<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav />
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-md-5 mb-3">
          <h1 class="mt-4" v-if="this.$store.state.sentOrder.requires_approval">Your order was sent for approval!</h1>
          <h1 class="mt-4" v-else>Yippee {{firstName}}!</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <img class="img-width mt-3" src="@/assets/images/order-confirmed.png" />
          <div class="mt-3 mb-5 mb-md-0" v-if="this.$store.state.sentOrder.requires_approval">
            We have received your order and sent it for approval to {{$store.getters.activeStore.name}} administrators. They will reply to this order shortly.
          </div>
          <div class="mt-3 mb-5 mb-md-0" v-else>
            We have received your order and also emailed the details to your email address {{email}}.
          </div>
        </div>
        <div class="col-md-6 summary-background pb-5 pb-md-0">
          <OrderSummary v-bind:showAll=true v-bind:showSent=true v-bind:showEdit=false />
          <div class="row">
            <div class="col-12 col-md-6">
              <router-link class="" :to="'/' + $store.getters.activeStore.path">
                <button class="btn btn-lg btn-primary btn-block mt-2 mt-md-5">Back to products</button>
              </router-link>
            </div>
            <div class="col-12 col-md-6" v-if="!this.$store.state.sentOrder.requires_approval">
              <button class="btn btn-lg btn-outline-primary btn-block mt-3 mt-md-5" v-on:click="openDeliveryNote()">Print delivery note</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Footer from '@/modules/general/components/Footer.vue'
import OrderSummary from '../components/OrderSummary'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";

export default {
  name: 'OrderConfirmation',
  components: {
    SidebarNav,
    TopNav,
    Footer,
    OrderSummary
  },
  data() {
    return {
      selectedcc: null,
      selectedaddr: null
    }
  },
  beforeRouteLeave (to, from, next) {
    // clear sentOrder data when leaving order confirmation page
    this.$store.dispatch('clearSentOrderData');
    next();
  },
  computed: {
    firstName () {
      const profile = this.$store.getters.profile;
      const name = profile.user.first_name;
      return name;
    },
    email () {
      return this.$store.getters.profile.user.email;
    }
  },
  methods: {
    openDeliveryNote(){
      let routeData = this.$router.resolve(
      {
        name: 'deliverynote',
        params: {'storeId': this.$store.state.activeStore.id, 'orderId': this.$store.state.sentOrder.id}
      });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
